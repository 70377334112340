export const Offices = [
  {
    address: "г. Уфа, ул. Менделеева 219, ул. Революционная 173",
    phoneNumber: "+7 (347) 2-588-588, +7 (347) 2-588-588",
    email: "ufa@sbis.pro"
  },{
    address: "г. Санкт-Петербург, ул. Заневский проспект 7/7",
    phoneNumber: "+7 (812) 241-1-246",
    email: "spb@sbis.pro"
  },{
    address: "г. Екатеринбург, ул. Мамина-Сибиряка 40",
    phoneNumber: "+7 (343) 226-46-82",
    email: "ekb@sbis.pro"
  }
  ,{
    address: "г. Казань, ул. Баумана, д. 82",
    phoneNumber: " +7 (843) 207-05-04",
    email: "kazan@sbis.pro"
  }
]