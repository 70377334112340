import React, {useEffect, useState} from 'react';
// import ContactUsModal from "../ContactUsModal/ContactUsModal";

import styles from './MainButton.module.scss'
import icon from './icon.svg'



const MainButton = () => {
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    (
      function(win, doc, url) {
        let sws = doc.createElement('script');
        sws.type = 'text/javascript';
        sws.async = true;
        sws.src = url;

        let pfs = doc.getElementsByTagName('script')[0];
        // @ts-ignore
        pfs.parentNode.insertBefore(sws, pfs);
      }
    )(window, document, "https://site.sbis.ru/consultant-widget/script/0e87350d-a80c-4a15-b6f0-6c3ead59373d");
  }, [])

    return (
      <>
        <script type="text/javascript" data-saby-widget="0e87350d-a80c-4a15-b6f0-6c3ead59373d"></script>
        <button className={styles.mainButton} onClick={() => setModalActive(!modalActive)}>
          <img src={icon} alt="Иконка" className={styles.icon}/>
          <p className={styles.buttonText}>
            Оставить заявку
          </p>
        </button>
        {/*<ContactUsModal active={modalActive} setActive={setModalActive}/>*/}
      </>
    );
};

export default MainButton;